/* ./src/index.{css, less, sass, scss} */
@tailwind base;
@tailwind components;
@tailwind utilities;
body,
html,
p {
  padding: 0;
  margin: 0;
}
html{
  overflow-x: hidden;
}
p{
  line-height: 1.2;
}
span{
  vertical-align: middle;
}

pre,code {
  white-space: pre-wrap;
}

pre{
  padding: 6px;
  background-color: #f5f5f5;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  line-height: 2;
  font-weight: bold;
}
h1{
  font-size: 1.8rem;
}
h2{
  font-size: 1.6rem;
}
h3{
  font-size: 1.5rem;
}
h4{
  font-size: 1.4rem;
}
h5{
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover{
  color: rgba(0,0,0,.6);
}
img{
  max-width: 100%;
}

button{
padding: 4px 20px;
font-size: 14px;
background-color: transparent;
border: 1px solid;
/* border-radius: 4px; */
cursor: pointer;
}

button[disabled]{
  cursor: not-allowed;
  background-color: gray !important;
}

textarea, input{
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  resize: none;
  border: 1px solid;
}

ul{
  padding: 0;
  margin: 0;
  list-style: none;
}

.one-line{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-pointer-event{
  pointer-events: none;
}



.w-18{
  width: 72px;
}

.mb-10{
  margin-bottom: 10px;
}

.zoom{
  zoom: 0.8;
}

.text-small{
  font-size: 12px;
}

.f-right{
  float: right;
}

.line-normal{
  line-height: 28px;
}
.invert-color{
  filter: invert(100%);
}

div.no-result{
  padding: 20px 10px;
  margin-top: 16px;
  font-size: 28px;
  font-weight: bold;
  color: #ddd;
  text-align: center;
  cursor: pointer;
}

button.primary:hover {
  background-color: #000;
  color: #fff;
}

@media (max-width: 769px){
  .show-pc{
    display: none;
  }
  .mobile-wrap{
    flex-wrap: wrap;
  }
}






/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
  box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.5);
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

.no-scroll::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.several-line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.one-line{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

h3{
font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

blockquote{
  padding-left: 32px;
  border-left: 3px solid #b2b2b2;
  margin: 10px 0;
  font-weight: bold;
  color: #969696;
}

svg {
  display: inline-block;
  cursor: pointer;
}

.no-scroll::-webkit-scrollbar{
  display: none;
}

.disabled{
  position: relative;
  pointer-events: none;
}
.disabled:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
